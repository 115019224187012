// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--Lmc8t {\n  margin: 0 auto;\n  padding-top: 32px;\n  font-family: Inter;\n  max-width: 1120px;\n}\n@media only screen and (max-width: 600px) {\n  .s-module__wrap--Lmc8t {\n    padding: 24px 16px 80px;\n    width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/SalesProviderExp/components/Account/s.module.less"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,iBAAA;EAEA,kBAAA;EACA,iBAAA;AAAJ;AAGA;EACI;IACI,uBAAA;IACA,WAAA;EADN;AACF","sourcesContent":[".wrap {\n    margin: 0 auto;\n    padding-top: 32px;\n    // width: 1114px;\n    font-family: Inter;\n    max-width: 1120px;\n}\n\n@media only screen and (max-width: 600px) {\n    .wrap {\n        padding: 24px 16px 80px;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--Lmc8t"
};
export default ___CSS_LOADER_EXPORT___;
