import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import { Button, Modal, Spin, message } from 'antd';
import PrcessBar from './components/ProgressBar';
import Checkout from './components/Checkout';
import Agreement from './components/Agreement';
// import Schedule from './components/Schedule';
import Account from './components/Account';
import Complete from './components/Complete';
import Logo from 'assets/logo/kiwihealth.png';
import { EStep, stepNameToNumber } from './constant';
import { getUrlParam } from 'utils/common';
import { getSalesLinkDetail, changeSalesLinkStatus, getStripePaymentStatus, checkoutNext, checkoutPayment } from 'api/salesProvider';
import { ISalesProvider } from 'types/provider';
import { ECurrentStep } from 'types/salesProvider';
import Helloklairty from './components/Helloklarity';
import { EPlanType } from 'types/contractLink';
import { getProviderNameDisplay } from 'utils/provider';

// const testuuid = '9b7ef3ecfb134ce7bde2e746e5d5b58f';

const SalesProviderExp = () => {
    const uuid = getUrlParam('uuid');
    const from = getUrlParam('from');
    const sessionId = getUrlParam('session_id');
    const formId = useRef('formId');
    const [currentStep, setCurrentStep] = useState(EStep.CHECKOUT);
    const [nextDisabled, setNextDisabled] = useState(true);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [salesDetail, setSalesDetail] = useState<ISalesProvider>();
    const [syncStepLoading, setSyncStepLoading] = useState(true);
    const nextButtonCallback = useRef<(e: any) => void>();
    const [showPaySuccessTip, setShowPaySuccessTip] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [needHelpCalendlyLoading, setNeedHelpCalendlyLoading] = useState(true);

    // 根据后端api返回的currentStep进行判断
    const syncCurrentStep = useCallback(async (sn: ECurrentStep) => {
        setSyncStepLoading(true);
        let stepNumber = stepNameToNumber[sn];

        // stripe 付完款之后，通知到后端改step是异步的，可能会出现付款成功，但是步骤还在LS的情况
        // 如果stripe api是complete，那么前端就设置为Agreement
        if (sn === ECurrentStep.LS && from === 'stripe' && sessionId) {
            try {
                const res = await getStripePaymentStatus(sessionId);

                if (res && !res.error) {
                    if (res.data.data === 'complete') {
                        stepNumber = EStep.AGREEMENT;
                    }
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }
        }

        setCurrentStep(stepNumber);
        setSyncStepLoading(false);
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
    }, [from, sessionId]);

    const changeLinkStatus = useCallback(async (id: string) => {
        await changeSalesLinkStatus(id);
    }, []);

    const getSalesDetail = useCallback(async () => {
        if (uuid) {
            const res = await getSalesLinkDetail(uuid);

            if (res && !res.error) {
                const detail = res.data.data as ISalesProvider;

                if (detail) {
                    setSalesDetail(detail);

                    const { linkStatus, linkUuid, checkOut, currentStep: backendStep } = detail;
                    const { payPlan } = checkOut || {};
                    const { id } = payPlan || {};

                    await syncCurrentStep(backendStep as ECurrentStep);

                    if (id) {
                        if (linkStatus === 'LS' || linkStatus === 'LC') {
                            changeLinkStatus(linkUuid);
                        }
                    }
                }
            }
        }
    }, [changeLinkStatus, syncCurrentStep, uuid]);

    useEffect(() => {
        getSalesDetail();
    }, [getSalesDetail]);

    const handleNext = useCallback((sn: ECurrentStep) => {
        syncCurrentStep(sn);
    }, [syncCurrentStep]);

    // const handleExit = useCallback(() => {

    // }, []);

    const generateHelpSchedule = useCallback(() => {
        // const objString = new URLSearchParams({
        //     hide_gdpr_banner: '1',
        // }).toString();
        const { firstName = '', lastName = '', gmail, bookingLinkUrl } = salesDetail?.sales || {};
        const objString = new URLSearchParams({
            hide_gdpr_banner: '1',
            first_name: firstName,
            last_name: lastName,
        }).toString();

        Calendly.initInlineWidget({
            url: `${bookingLinkUrl}?${objString}`,
            // url: `https://calendly.com/kunqian-fan/11?${objString}`,
            parentElement: document.getElementById('calendly'),
            prefill: {
                email: gmail,
            },
        });
    }, [salesDetail?.sales]);

    const handleNeedHelp = useCallback(() => {
        setShowHelpModal(true);
        setNeedHelpCalendlyLoading(true);

        setTimeout(() => {
            generateHelpSchedule();
        }, 300);
        // const { firstName = '', lastName = '', gmail, bookingLinkUrl } = salesDetail?.sales || {};
        // const objString = new URLSearchParams({
        //     hide_gdpr_banner: '1',
        //     first_name: firstName,
        //     last_name: lastName,
        // }).toString();

        // Calendly.initPopupWidget({
        //     url: `${bookingLinkUrl}?${objString}`,
        //     prefill: {
        //         email: gmail,
        //     },
        // });
    }, [salesDetail]);

    const handleCheckoutNext = useCallback(async () => {
        if (salesDetail?.linkUuid) {
            const paymentSubmitData = {
                linkUuid: salesDetail.linkUuid,
            };
            try {
                const res = await checkoutPayment(paymentSubmitData);

                if (res && !res.error) {
                    const detail = res.data.data;
                    setSalesDetail(detail);
                    const sn = detail?.currentStep as ECurrentStep || ECurrentStep.PR;

                    handleNext(sn);
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }, [handleNext, salesDetail]);

    const setNextButtonCallback = useCallback((callback: (e: any) => void | null) => {
        nextButtonCallback.current = callback;
    }, []);

    const handleCheckout = useCallback(async (e: any) => {
        if (typeof nextButtonCallback.current === 'function') {
            setCheckoutLoading(true);

            try {
                await nextButtonCallback.current(e);
            } catch (error) {
                console.error(error);
            }

            setCheckoutLoading(false);
        }
    }, []);

    // const handleScheduleSuccess = useCallback(async () => {
    //     const uuid = salesDetail?.linkUuid;
    //     if (uuid) {
    //         setCheckoutLoading(true);

    //         try {
    //             const res = await salesScheduled(uuid);

    //             if (res && !res.error) {
    //                 console.info(res);

    //                 const scheduleResp = (res.data?.data || {}) as IScheduleResponse;
    //                 const accountStatus = scheduleResp?.accountReview?.accountStatus;

    //                 if (accountStatus) {
    //                     // let stepNumber = 1;
    //                     let stepNumber = 2;

    //                     if (accountStatus !== EBindType.CREATED) {
    //                         // stepNumber = 0.5;
    //                         stepNumber = 1;
    //                     }
    //                     handleNext(stepNumber);
    //                 } else {
    //                     message.error('Ops, Something is wrong with account status');
    //                 }
    //             } else {
    //                 message.error(res?.error);
    //             }
    //         } catch (e) {
    //             console.error(e);
    //         }

    //         setCheckoutLoading(false);
    //     }
    // }, [handleNext, salesDetail]);

    const commonProps = useMemo(() => {
        return {
            linkUUID: uuid,
            salesDetail,
            setCheckoutLoading: (status: boolean) => setCheckoutLoading(!!status),
            setNextButtonDisabled: (status: boolean) => setNextDisabled(status),
            onNext: handleNext,
            setSalesDetail,
        };
    }, [handleNext, salesDetail, uuid]);

    const componentRender = useMemo(() => {
        let content = null;
        const plan = salesDetail?.checkOut?.payPlan?.type;

        const CheckoutComponent = plan === EPlanType.HELLO_KLARITY ? Helloklairty : Checkout;

        switch (currentStep) {
            case EStep.CHECKOUT:
                content = <CheckoutComponent setShowPaySuccessTip={setShowPaySuccessTip} {...commonProps} setNextButtonCallback={setNextButtonCallback} />;
                break;
            case EStep.AGREEMENT:
                content = <Agreement showPaySuccessTip={showPaySuccessTip} setShowPaySuccessTip={setShowPaySuccessTip} {...commonProps} formId={formId.current} />;
                break;
            // case EStep.SCHEDULE:
            //     content = <Schedule {...commonProps} />;
            //     break;
            case EStep.ACCOUNT:
                content = <Account {...commonProps} formId={formId.current} />;
                break;
            case EStep.COMPLETE:
                content = <Complete {...commonProps} />;
                break;
            default: content = null;
        }

        return content;
    }, [commonProps, currentStep, salesDetail, showPaySuccessTip, setNextButtonCallback]);

    const nextButtonRender = useMemo(() => {
        const plan = salesDetail?.checkOut?.payPlan?.type;
        if (currentStep === EStep.CHECKOUT) {
            if (plan === EPlanType.HELLO_KLARITY) {
                return <Button loading={checkoutLoading} onClick={handleCheckoutNext} type="primary">Next</Button>;
            }
            return <Button loading={checkoutLoading} onClick={handleCheckout} type="primary">Check out</Button>;
        } else if (currentStep === EStep.AGREEMENT || currentStep === EStep.ACCOUNT) {
            return (
                <Button
                    type="primary"
                    htmlType="submit"
                    form={formId.current}
                    loading={checkoutLoading}
                    disabled={currentStep === EStep.AGREEMENT ? nextDisabled : false}
                >Next
                </Button>
            );
        }
        // } else if (currentStep === EStep.SCHEDULE) {
        //     return <Button loading={checkoutLoading} disabled={nextDisabled} onClick={handleScheduleSuccess} type="primary">Next</Button>;
        // } else if (currentStep === EStep.COMPLETE) {
        return null;
        // }
        // return <Button loading={checkoutLoading} disabled={nextDisabled} onClick={() => handleNext()} type="primary">Next</Button>;
    }, [salesDetail, currentStep, checkoutLoading, handleCheckout, handleCheckoutNext, nextDisabled]);

    useEffect(() => {
        const isCalendlyEvent = (e: any) => {
            return e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0;
        };

        const eventCallback = (e: any) => {
            if (isCalendlyEvent(e)) {
                const calendlyEvent = e.data.event;

                if (calendlyEvent === 'calendly.event_type_viewed') {
                    setNeedHelpCalendlyLoading(false);
                }
            }
        };

        window.addEventListener('message', eventCallback);

        return () => {
            window.removeEventListener('message', eventCallback);
        };
    }, []);

    if (syncStepLoading) {
        return <Spin spinning />;
    }

    return (
        <div className={s.wrap}>
            <Modal
                width={1000}
                open={showHelpModal}
                footer={null}
                title={null}
                onCancel={() => {
                    setShowHelpModal(false);
                    setNeedHelpCalendlyLoading(false);
                }}
                destroyOnClose
            >
                <div className={s.helpWrap}>
                    <div className={s.left}>
                        <div className={s.title}>{`Contact ${getProviderNameDisplay({
                            firstName: salesDetail?.sales.firstName,
                            lastName: salesDetail?.sales.lastName,
                        })}`}
                        </div>
                        <div className={s.detailItem}>
                            <div className={s.posi} />
                            <div className={s.text}>{salesDetail?.sales.title}</div>
                        </div>
                        <div className={s.detailItem}>
                            <div className={s.tel} />
                            <div className={s.text}>{salesDetail?.sales.tel}</div>
                        </div>
                        <div className={s.detailItem}>
                            <div className={s.email} />
                            <div className={s.text}>{salesDetail?.sales.gmail}</div>
                        </div>
                    </div>
                    <div className={s.right}>
                        <Spin spinning={needHelpCalendlyLoading} />
                        <div id="calendly" className={s.calendlyWrap} />
                    </div>
                </div>
            </Modal>
            <div className={s.header}>
                <img className={s.logo} src={Logo} alt="Kiwi Health logo" />
                <div className={s.needHelp} onClick={handleNeedHelp}>Need Help?</div>
            </div>
            <div className={`${s.body} ${currentStep === EStep.COMPLETE ? s.heavyBg : s.lightBg}`}>
                {componentRender}
            </div>
            <PrcessBar step={currentStep} />
            <div className={s.footer}>
                <div className={s.leftPart}>
                    {/* <Button onClick={handleExit}>Exit</Button> */}
                    <a className={s.helpLink} onClick={handleNeedHelp}>Need Help?</a>
                </div>
                <div className={s.rightPart}>
                    {nextButtonRender}
                </div>
            </div>
        </div>
    );
};

export default SalesProviderExp;
