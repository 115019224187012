// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__footer--fFen0 {\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  border-top: 1px solid #eee;\n  background: #ffffff;\n}\n.s-module__footer--fFen0 .s-module__footerInner--Kf8bA {\n  width: 90%;\n  max-width: var(--content-width);\n  margin: 0 auto;\n  line-height: 60px;\n  height: 60px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n}\n.s-module__footer--fFen0 .s-module__back--zpfIo {\n  color: var(--primary-color);\n  margin-left: 24px;\n  cursor: pointer;\n  font-weight: 500;\n}\n.s-module__footer--fFen0 .s-module__back--zpfIo:hover {\n  text-decoration: underline;\n  opacity: 0.7;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ProviderOnboardingPage/components/Footer/s.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,SAAA;EACA,WAAA;EACA,0BAAA;EACA,mBAAA;AACF;AANA;EAOI,UAAA;EACA,+BAAA;EACA,cAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AAEJ;AAjBA;EAkBI,2BAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;AAEJ;AAvBA;EAwBI,0BAAA;EACA,YAAA;AAEJ","sourcesContent":[".footer{\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  border-top: 1px solid #eee;\n  background: rgba(255, 255, 255, 1);\n  .footerInner{\n    width: 90%;\n    max-width: var(--content-width);\n    margin: 0 auto;\n    line-height: 60px;\n    height: 60px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n  }\n  .back{\n    color: var(--primary-color);\n    margin-left: 24px;\n    cursor: pointer;\n    font-weight: 500;\n  }\n  .back:hover{\n    text-decoration: underline;\n    opacity: 0.7;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "s-module__footer--fFen0",
	"footerInner": "s-module__footerInner--Kf8bA",
	"back": "s-module__back--zpfIo"
};
export default ___CSS_LOADER_EXPORT___;
