// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--FXn9p {\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 500;\n  font-size: 12px;\n  line-height: 16px;\n  padding: 2px 10px;\n  border-radius: 10px;\n  text-align: center;\n}\n.s-module__wrap--FXn9p.s-module__superadmin--J84GX {\n  background: var(--red-100);\n  color: var(--red-800);\n}\n.s-module__wrap--FXn9p.s-module__opsadmin--llhwP {\n  background: var(--yellow-100);\n  color: var(--yellow-800);\n}\n.s-module__wrap--FXn9p.s-module__ops--SFrqK {\n  background: var(--blue-100);\n  color: var(--blue-800);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/AccessManagement/Components/RoleLabel/s.module.less"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AACI;EACI,0BAAA;EACA,qBAAA;AACR;AACI;EACI,6BAAA;EACA,wBAAA;AACR;AACI;EACI,2BAAA;EACA,sBAAA;AACR","sourcesContent":[".wrap {\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 12px;\n    line-height: 16px;\n    padding: 2px 10px;\n    border-radius: 10px;\n    text-align: center;\n\n    &.superadmin {\n        background: var(--red-100);\n        color: var(--red-800);\n    }\n    &.opsadmin {\n        background: var(--yellow-100);\n        color: var(--yellow-800);\n    }\n    &.ops {\n        background: var(--blue-100);\n        color: var(--blue-800);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--FXn9p",
	"superadmin": "s-module__superadmin--J84GX",
	"opsadmin": "s-module__opsadmin--llhwP",
	"ops": "s-module__ops--SFrqK"
};
export default ___CSS_LOADER_EXPORT___;
