import React from 'react';
import { Button, Divider, Input } from 'antd';
import KiwiBanner from 'components/KiwiBanner';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import GoogleLogin from './components/GoogleLogin';
import { PATH } from 'constants/path';
import s from './s.module.less';
import WarningIcon from 'assets/common/warningRed.svg';
import { isValidEmail } from 'utils/form';
import Check from 'assets/common/check.svg';
import ForgotPassword from './components/ForgotPassword';
import { loginWithPwd } from 'api/public';
import { ProviderBasicInfo } from 'types/provider';
import ProviderStore from 'store/Provider';
import { setHasOnceSuccessLogin, setUserId, setUserToken } from 'utils/localstore';
import { getResetFailEmail, setResetFailEmail } from 'utils/sessionStorage';
import { clearUniprofileStepStorage } from 'utils/uniprofile';

export default function ProviderLoginPage() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [psw, setPsw] = React.useState('');
    const [email, setEmail] = React.useState(state?.email || '');
    const [showPwsError, setShowPwsError] = React.useState(false);
    const [showResetSuccess, setShowResetSuccess] = React.useState(!!(state?.showResetSuccess));
    const [showForgotPassword, setShowForgotPassword] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const showEmailError = email && !isValidEmail(email);
    const [failEmail, setFailEmail] = React.useState('');
    const [, set] = ProviderStore.useStore();

    React.useEffect(() => {
        const resetFail = getResetFailEmail();
        if (resetFail) {
            setFailEmail(resetFail);
            setShowForgotPassword(true);
            setResetFailEmail();
        }
    }, []);

    const loginWithPwdFun = async () => {
        setLoading(true);
        const result = await loginWithPwd({
            email,
            password: psw,
        });
        if (!result.error) {
            const info:ProviderBasicInfo = result.data.data.authPaylod;
            const { token } = result.data.data;
            setHasOnceSuccessLogin('true');
            set('data', {
                ...info,
            });
            setUserId(info.id);
            // if (isOps) {
            //     setOpsToken(token);
            // } else {
            //     setUserToken(token);
            // }
            clearUniprofileStepStorage();
            setUserToken(token);
            navigate(`${PATH.DASHBOARD}/${PATH.PROVIDER_HOME}`);
        } else {
            setShowPwsError(true);
        }
        setLoading(false);
    };

    const onGoogleLoginCallback = (result: any) => {
        if (!result.error) {
            const info:ProviderBasicInfo = result.data.data.authPaylod;
            const { token } = result.data.data;
            setHasOnceSuccessLogin('true');
            set('data', {
                ...info,
            });
            setUserId(info.id);
            clearUniprofileStepStorage();
            // if (isOps) {
            //     setOpsToken(token);
            // } else {
            //     setUserToken(token);
            // }
            setUserToken(token);
            navigate(`${PATH.DASHBOARD}/${PATH.PROVIDER_HOME}`);
            setLoginTimeToLocalStorage();
        }
    };
    return (
        <div className={`${s.wrap} flex`}>
            <div className={s.left}>
                <div className={s.container}>
                    <h2 className={s.headerTitle}>
                        <img src="https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/ui/logo_kiwihealth_by_klarity_380x96.png" />
                    </h2>
                    <div className={s.subTitle}>
                        Log in
                    </div>
                    <div className={s.navTips}>
                        New to Kiwi Health?
                        <span
                            style={{ color: '#006957', cursor: 'pointer' }}
                            onClick={() => {
                                navigate(PATH.SIGNUP);
                                //google bug, need reload
                                window.location.reload();
                            }}
                        > Sign up
                        </span>
                    </div>
                    <div className={s.form}>
                        {/* disable chrome auto fill */}
                        <input type="text" style={{ display: 'none' }} name="email" disabled autoComplete="off" />
                        <input type="password" style={{ display: 'none' }} disabled autoComplete="off" />
                        <div className={s.label}>Email address</div>
                        <div className={s.value}>
                            <Input
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setShowResetSuccess(false);
                                }}
                                autoComplete="off"
                                value={email}
                                size="large"
                                status={showEmailError ? 'error' : undefined}
                            />
                        </div>
                        <div className={s.label}>Password</div>
                        <div className={s.value} style={{ marginBottom: '12px' }}>
                            <Input.Password
                                value={psw}
                                autoComplete="off"
                                onChange={(e) => {
                                    setPsw(e.target.value);
                                    setShowResetSuccess(false);
                                }}
                                size="large"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        if (email && psw) {
                                            loginWithPwdFun();
                                        }
                                    }
                                }}
                            />
                        </div>
                        <div className={showPwsError ? `${s.existError} ${s.existErrorShow}` : s.existError}>
                            <img src={WarningIcon} />
                            <span>The password you&apos;ve entered doesn&apos;t match the email provided. Please double-check and try again.</span>
                        </div>

                        <div className={showResetSuccess ? `${s.success} ${s.successShow}` : s.success}>
                            <img src={Check} />
                            <span>Password reset successful</span>
                        </div>

                        <div
                            className={s.forgetWrap}
                            onClick={() => {
                                setShowForgotPassword(true);
                            }}
                        >
                            Forgot password?
                        </div>
                        <Button
                            onClick={loginWithPwdFun}
                            style={{ marginTop: 4 }}
                            block
                            loading={loading}
                            type="primary"
                            size="large"
                            disabled={!email || !psw}
                        >
                            Continue
                        </Button>
                    </div>

                    <Divider plain className={s.divi}>Or</Divider>

                    <div className={s.googleWrap}>
                        <GoogleLogin
                            onCallback={onGoogleLoginCallback}
                        />
                    </div>
                </div>
            </div>
            <div className={s.right}>
                <div className={s.banner}>
                    <KiwiBanner />
                </div>
            </div>
            <ForgotPassword
                defaultFailEmail={failEmail}
                show={showForgotPassword}
                onCancel={() => {
                    //clear email
                    setFailEmail('');
                    setShowForgotPassword(false); }}
            />
        </div>
    );
}
