// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--HWxJ9 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 18px 0;\n}\n.s-module__wrap--HWxJ9 img {\n  width: 360px;\n  max-width: 90%;\n}\n.s-module__wrap--HWxJ9 p {\n  width: 500px;\n  max-width: 94%;\n  text-align: center;\n  font-size: 18px;\n  line-height: 22px;\n}\n.s-module__wrap--HWxJ9 .s-module__p1--BPy1q {\n  font-weight: 500;\n  margin-bottom: 8px;\n}\n.s-module__wrap--HWxJ9 .s-module__p3--qRtFP {\n  font-size: 14px;\n  line-height: 20px;\n  color: #6B7280;\n  margin-top: 8px;\n}\n.s-module__wrap--HWxJ9 a {\n  color: var(--primary-color);\n}\n.s-module__wrap--HWxJ9 a:hover {\n  text-decoration: underline;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ProviderOnboardingPage/components/Finish/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;AACF;AALA;EAMI,YAAA;EACA,cAAA;AAEJ;AATA;EAUI,YAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;AAEJ;AAhBA;EAiBI,gBAAA;EACA,kBAAA;AAEJ;AApBA;EAqBI,eAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AAEJ;AA1BA;EA2BI,2BAAA;AAEJ;AA7BA;EA8BI,0BAAA;AAEJ","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 18px 0;\n  img {\n    width: 360px;\n    max-width: 90%;\n  }\n  p{\n    width: 500px;\n    max-width: 94%;\n    text-align: center;\n    font-size: 18px;\n    line-height: 22px;\n  }\n  .p1{\n    font-weight: 500;\n    margin-bottom: 8px;\n  }\n  .p3{\n    font-size: 14px;\n    line-height: 20px;\n    color: #6B7280;\n    margin-top: 8px;\n  }\n  a{\n    color: var(--primary-color);\n  }\n  a:hover{\n    text-decoration: underline;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--HWxJ9",
	"p1": "s-module__p1--BPy1q",
	"p3": "s-module__p3--qRtFP"
};
export default ___CSS_LOADER_EXPORT___;
