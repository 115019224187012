// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__content--E6a9P {\n  width: 90%;\n  margin: 0 auto;\n  color: var(--gray-700);\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px;\n  margin-bottom: 36px;\n}\n.s-module__logo--xovHP {\n  height: 36px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/MobileNotSupportPage/s.module.less"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,cAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AACF;AAEA;EACE,YAAA;AAAF","sourcesContent":[".content {\n  width: 90%;\n  margin: 0 auto;\n  color: var(--gray-700);\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px;\n  margin-bottom: 36px;\n}\n\n.logo{\n  height: 36px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "s-module__content--E6a9P",
	"logo": "s-module__logo--xovHP"
};
export default ___CSS_LOADER_EXPORT___;
